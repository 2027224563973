var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-design-login"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '请输入昵称' }] }
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '请输入昵称' }] }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"口令"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {
            rules: [{ required: true, message: '请输入口令' }]
          }
        ]),expression:"[\n          'code',\n          {\n            rules: [{ required: true, message: '请输入口令' }]\n          }\n        ]"}],attrs:{"type":"password"}})],1),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提交 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }