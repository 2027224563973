<template>
  <div class="app-design-login">
    <a-form :form="form" class="login-form" @submit="handleSubmit">
      <a-form-item label="昵称">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '请输入昵称' }] }
          ]"
        />
      </a-form-item>
      <a-form-item label="口令">
        <a-input
          v-decorator="[
            'code',
            {
              rules: [{ required: true, message: '请输入口令' }]
            }
          ]"
          type="password"
        />
      </a-form-item>
      <a-button class="login-form-button" type="primary" html-type="submit">
        提交
      </a-button>
    </a-form>
  </div>
</template>

<script>
import { designLogin } from "@/service/auth";
const DesignSvc = require("@/service/design");

export default {
  data() {
    return {
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" })
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log("Received values of form: ", values);

          await this.login(values);
        }
      });
    },

    async login(val) {
      try {
        const { token, id } = await DesignSvc.login(val);
        designLogin(token, id);

        // 跳转首页
        this.$router.replace("/decoration/home");
      } catch (err) {
        console.error(err);
        this.$message.error("登录失败，错误：" + err.message);
      }
    }
  }
};
</script>

<style lang="scss">
.app-design-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    width: 300px;
  }
  .login-form-button {
    width: 100%;
  }
}
</style>
